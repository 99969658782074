import {
  CalendarViewRouteData,
  CalendarViewTrailerSectionData,
} from "../../ducks/data/calendar-view/types";
import { useCalendarViewControls } from "./CalendarViewControlContext";
import { DateTime } from "luxon";
import CalendarViewRowDayVerticalLines from "./CalendarViewRowDayVerticalLines";
import CalendarViewTimelineTopBar from "./CalendarViewTimelineTopBar";
import CalendarViewRouteWithStops from "./CalendarViewRouteWithStops";
import React, { useEffect, useRef, useState } from "react";
import useResizeObserver from "use-resize-observer";
import clsx from "clsx";
import { useDrop } from "react-dnd";
import { CARGO_LIST_DRAGGABLE_CARGO } from "../DispatchPage/CargoList/CargoCard";
import { CALENDAR_VIEW_DRAGGABLE_CARGO_STOP } from "./constants";
import { CalendarViewDrawnStop } from "./interfaces";
import RouteDropDialog from "./RouteDropDialog";
import { store, useAppDispatch, useSelector } from "../../redux-store";
import {
  selectDataForCalendarView,
  selectStartDate,
} from "../../ducks/data/calendar-view/selectors";
import useMoveCargoToNewRoute from "./use-move-cargo-to-new-route";
import { shiftCargoStopsTime } from "../../ducks/data/calendar-view";
import AddTimeFirstRouteStopDialog from "./AddTimeFirstRouteStopDialog";

// TODO: this really should be passed from one hook to another, but the right value doesn't get through. Still, it should be implemented properly
let colWithOutsideScope: number;
let viewTimeIntervalOutsideScope: string;
let moveSingleStopEnabledOutsideScope = false;
let calendarViewDataOutsideScope: CalendarViewTrailerSectionData[];
let hoverPositionOutsideScope: DateTime | null;

export type CalendarViewRouteItemDroppedProps = {
  cargoId: string;
  routesForDropTrailerRowFiltered: string[];
  dropTrailerId: string;
  originRouteId: string | null;
  originRouteHasMoreThanOneCargo: boolean;
};

const CalendarViewTrailerRowsSection = ({
  trailerRowsSection,
}: {
  trailerRowsSection: CalendarViewTrailerSectionData;
}) => {
  const { ref, height } = useResizeObserver<HTMLDivElement>();
  const rowsOfOverlappingRoutes: { routes: CalendarViewRouteData[] }[] = [];
  const {
    viewTimeInterval,
    setRowSectionHeights,
    rowSectionHeights,
    colWidth,
    moveSingleStopEnabled,
    hoverPosition,
  } = useCalendarViewControls();
  const { moveCargoToNewRoute } = useMoveCargoToNewRoute();
  const [itemDroppedProps, setItemDroppedProps] =
    useState<CalendarViewRouteItemDroppedProps | null>(null);
  const [addTimeFirstStopDialogProps, setAddTimeFirstStopDialogProps] =
    useState<{
      cargoId: string;
      originRouteId: string | null;
      stopAddress: string;
    } | null>(null);
  const searchStartDate = useSelector(selectStartDate);
  const dispatch = useAppDispatch();

  moveSingleStopEnabledOutsideScope = moveSingleStopEnabled;
  calendarViewDataOutsideScope = useSelector(selectDataForCalendarView);
  colWithOutsideScope = colWidth;
  viewTimeIntervalOutsideScope = viewTimeInterval;
  hoverPositionOutsideScope = hoverPosition;

  for (const route of trailerRowsSection.routes) {
    let addedToRow = false;
    for (const row of rowsOfOverlappingRoutes) {
      let canFitInRow = true;
      for (const exisingRouteOnRow of row.routes) {
        const existingRouteOnRowStartDateTime = DateTime.fromFormat(
          exisingRouteOnRow.routeStartDate,
          "yyyy-MM-dd"
        ).set({
          hour: parseInt(exisingRouteOnRow.routeStartTime.split(":")[0]),
          minute: parseInt(exisingRouteOnRow.routeStartTime.split(":")[1]),
        });
        const existingRouteOnRowEndDateTime = DateTime.fromFormat(
          exisingRouteOnRow.routeEndDate,
          "yyyy-MM-dd"
        ).set({
          hour: parseInt(exisingRouteOnRow.routeEndTime.split(":")[0]),
          minute: parseInt(exisingRouteOnRow.routeEndTime.split(":")[1]),
        });
        const routeStartDateTime = DateTime.fromFormat(
          route.routeStartDate,
          "yyyy-MM-dd"
        ).set({
          hour: parseInt(route.routeStartTime.split(":")[0]),
          minute: parseInt(route.routeStartTime.split(":")[1]),
        });
        const routeEndDateTime = DateTime.fromFormat(
          route.routeEndDate,
          "yyyy-MM-dd"
        ).set({
          hour: parseInt(route.routeEndTime.split(":")[0]),
          minute: parseInt(route.routeEndTime.split(":")[1]),
        });

        const overlaps =
          existingRouteOnRowStartDateTime < routeEndDateTime &&
          existingRouteOnRowEndDateTime > routeStartDateTime;
        if (overlaps) {
          canFitInRow = false;
          break;
        }
      }
      if (canFitInRow) {
        row.routes.push(route);
        addedToRow = true;
        break;
      }
    }
    if (!addedToRow) {
      rowsOfOverlappingRoutes.push({ routes: [route] });
    }
  }

  const [{ isOver }, drop] = useDrop(() => ({
    accept: [CARGO_LIST_DRAGGABLE_CARGO, CALENDAR_VIEW_DRAGGABLE_CARGO_STOP],
    collect: (monitor) => ({
      isOver:
        !moveSingleStopEnabledOutsideScope && monitor.isOver({ shallow: true }),
    }),
    drop: async (
      item:
        | {
            drawnStop: CalendarViewDrawnStop;
          }
        | any,
      monitor
    ) => {
      if (moveSingleStopEnabledOutsideScope || monitor.didDrop()) {
        return;
      }
      if (monitor.getItemType() === CALENDAR_VIEW_DRAGGABLE_CARGO_STOP) {
        if (!hoverPositionOutsideScope) {
          return;
        }
        const { drawnStop } = item as { drawnStop: CalendarViewDrawnStop };
        if (drawnStop.trailerId === trailerRowsSection.trailerId) {
          const diffInMinutes = Math.floor(
            hoverPositionOutsideScope.diff(drawnStop.dateTime, "minutes")
              .minutes
          );
          dispatch(
            shiftCargoStopsTime({
              cargoId: drawnStop.cargoId,
              minutesAmount: diffInMinutes,
            })
          );
          return;
        }
      }
      let droppedCargoId: string | null = null;
      let dropDateTime: DateTime;
      switch (monitor.getItemType()) {
        case CARGO_LIST_DRAGGABLE_CARGO: {
          const { cargoId } = item as { cargoId: string };
          droppedCargoId = cargoId;
          break;
        }
        case CALENDAR_VIEW_DRAGGABLE_CARGO_STOP: {
          const { drawnStop } = item as { drawnStop: CalendarViewDrawnStop };
          droppedCargoId = drawnStop.cargoId;
          break;
        }
        default:
          throw new Error(`Unexpected item ${JSON.stringify(item)}`);
      }

      // TODO: very unorthodox approach. any drawbacks?
      const cargoView =
        store.getState().data.cargoViews.entities[droppedCargoId];
      if (!cargoView) {
        return;
      }

      switch (viewTimeIntervalOutsideScope) {
        case "day":
          dropDateTime = searchStartDate;
          break;
        case "week":
          const clientOffset = monitor.getClientOffset();
          const dropTargetOffset = dropRef.current?.getBoundingClientRect();
          const dropXPosition = clientOffset!.x - dropTargetOffset!.left;
          dropDateTime = searchStartDate
            .startOf("week")
            .plus({
              hours: dropXPosition / colWithOutsideScope,
            })
            .startOf("day");
          break;
        default:
          throw new Error(`Unexpected viewTimeInterval ${viewTimeInterval}`);
      }

      const dropDateAsString = dropDateTime.toFormat("yyyy-MM-dd");
      const routesForDropTrailerRow =
        calendarViewDataOutsideScope.find(
          (row) => row.trailerId === trailerRowsSection.trailerId
        )?.routes || [];
      const routesForDropTrailerRowFiltered = routesForDropTrailerRow
        .filter(
          (r) =>
            r.routeStartDate <= dropDateAsString &&
            r.routeEndDate >= dropDateAsString
        )
        .map((r) => r.id);
      const originRouteHasMoreThanOneCargo =
        item.drawnStop?.routeId &&
        calendarViewDataOutsideScope!
          .flatMap((row) => row.routes)
          .find((route) => route.id === item.drawnStop.routeId)!.cargoOrder
          .length > 1;
      if (!routesForDropTrailerRowFiltered.length) {
        if (!cargoView.firstStop.time) {
          setAddTimeFirstStopDialogProps({
            cargoId: droppedCargoId,
            originRouteId: item.drawnStop?.routeId || null,
            stopAddress: [
              cargoView.firstStop.city,
              cargoView.firstStop.postcode,
            ].join(" - "),
          });
        } else {
          await moveCargoToNewRoute({
            dropTrailerId: trailerRowsSection.trailerId,
            originRouteId: item.drawnStop?.routeId || null,
            cargoId: droppedCargoId,
            originRouteHasMoreThanOneCargo,
          });
        }
        return;
      }

      setItemDroppedProps({
        cargoId: droppedCargoId,
        routesForDropTrailerRowFiltered,
        dropTrailerId: trailerRowsSection.trailerId,
        originRouteId: item.drawnStop?.routeId || null,
        originRouteHasMoreThanOneCargo,
      });
    },
  }));

  useEffect(() => {
    const newHeights = {
      ...rowSectionHeights,
      [trailerRowsSection.trailerId]: height,
    };
    if (JSON.stringify(newHeights) !== JSON.stringify(rowSectionHeights)) {
      setRowSectionHeights(newHeights as any);
    }
  }, [
    height,
    setRowSectionHeights,
    trailerRowsSection.trailerId,
    rowSectionHeights,
  ]);

  const classes = clsx("trailer-driver-full-row", {
    "has-item-hover-over": isOver,
  });

  const dropRef = useRef<HTMLDivElement>(null);

  drop(dropRef);

  return (
    <div className={classes} ref={dropRef}>
      {addTimeFirstStopDialogProps && (
        <AddTimeFirstRouteStopDialog
          onClose={() => setAddTimeFirstStopDialogProps(null)}
          cargoId={addTimeFirstStopDialogProps.cargoId}
          originRouteId={addTimeFirstStopDialogProps.originRouteId}
          dropTrailerId={trailerRowsSection.trailerId}
          stopAddress={addTimeFirstStopDialogProps.stopAddress}
        />
      )}
      {itemDroppedProps && (
        <RouteDropDialog
          onClose={() => setItemDroppedProps(null)}
          itemDroppedProps={itemDroppedProps}
        />
      )}
      <div ref={ref}>
        {viewTimeInterval === "week" && <CalendarViewRowDayVerticalLines />}
        <div className="trailer-route-row-section-wrapper">
          <CalendarViewTimelineTopBar hide />
          {rowsOfOverlappingRoutes.map((rowWithRoutes, i) => (
            <div key={i} className="trailer-route-row-section">
              {/*TODO: the timeline topbar component here is used to ensure row width, there's something wrong that it doesn't work out of the box*/}
              {rowWithRoutes.routes.map((route, j) => (
                <CalendarViewRouteWithStops key={j} route={route} />
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CalendarViewTrailerRowsSection;
