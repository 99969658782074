import axios, { getData } from "../../../axios";
import { useEffect, useState } from "react";
import {
  CargoEvent,
  cargoEventsT,
} from "../../../ducks/data/cargo-events/types";
import { List, Grid } from "@mui/material";
import * as tPromise from "io-ts-promise";
import { DriverEventItem } from "../../modals/display-cargo/CargoEvents";
import { LogSkeleton } from "../../skeletons";
import { useTranslation } from "react-i18next";
// import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { useShowCargoModal } from "../../../hooks";
import GenericAutocomplete from "../../GenericAutocomplete";
import { useSelector } from "../../../redux-store";
import { selectDrivers } from "../../../ducks/data/drivers/selectors";
import { DateTime } from "luxon";

const RouteLog = (props: { routeId: string }) => {
  const [events, setEvents] = useState<CargoEvent[] | null>(null);
  const [driverReportOptions, setDriverReportOptions] = useState<
    {
      id: string;
      value: string | null;
      label: string;
    }[]
  >([]);
  const drivers = useSelector(selectDrivers);
  useEffect(() => {
    const routeDriversIds = events?.map((e) => e.createdBy.driverId) || [];
    const routeDrivers = drivers.filter((d) => routeDriversIds.includes(d.id));
    if (routeDrivers.length) {
      setDriverReportOptions([
        { id: "all", label: "CSV - All drivers", value: "all" },
        ...routeDrivers.map((d) => ({
          id: d.id,
          label: `PDF - ${d.firstName} ${d.lastName}`,
          value: d.id,
        })),
      ]);
    }
  }, [drivers, events]);

  const { t } = useTranslation(["dispatching"]);
  useEffect(() => {
    axios
      .get(`/api/events/route-events/${props.routeId}`)
      .then(getData)
      .then(tPromise.decode(cargoEventsT))
      .then(setEvents);
  }, [props.routeId]);
  const showCargoModal = useShowCargoModal();

  const onSelection = (value: string) => {
    let driverId: string | null = null;
    if (value !== "all") {
      driverId = value;
    }
    window.open(
      driverId
        ? `/api/mileage-reports/pdf?routeId=${props.routeId}&driverId=${driverId}`
        : `/api/mileage-reports/csv?routeId=${props.routeId}`
    );
  };

  const startDayEvent = events?.find((e) => e.type === "TRIP_STARTED");
  const endDayEvent = events?.find((e) => e.type === "TRIP_ENDED");
  let diff = "";
  if (startDayEvent && endDayEvent) {
    const d = DateTime.fromISO(endDayEvent.createdAt).diff(
      DateTime.fromISO(startDayEvent.createdAt),
      ["hours", "minutes", "days"]
    );
    const duration = d.toObject();
    const { days, hours, minutes } = duration;

    if (days) {
      diff += `${days.toFixed(0)} d `;
    }
    if (hours) {
      diff += `${hours.toFixed(0)} h `;
    }
    if (minutes) {
      diff += `${minutes.toFixed(0)} m `;
    }

    if (diff) {
      diff = t("dispatching:openRouteView.totalTime", { time: diff });
    }
  }

  if (!events) {
    return null;
  }
  return (
    <>
      {events.length ? (
        <List>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            gap={2}
            pr={2}
          >
            <div className="ml-1">{diff}</div>
            <div style={{ minWidth: 260 }}>
              {driverReportOptions.length && (
                <GenericAutocomplete
                  label={t("dispatching:openRouteView.actions.drivingReport")}
                  disableAutoSelect
                  options={driverReportOptions}
                  value={null}
                  onChange={(value) => onSelection(value as string)}
                />
              )}
            </div>
            {/*<Button*/}
            {/*  component="a"*/}
            {/*  href={*/}
            {/*    driverReportId !== "all"*/}
            {/*      ? `/api/mileage-reports/pdf?routeId=${props.routeId}&driverId=${driverReportId}`*/}
            {/*      : `/api/mileage-reports/csv?routeId=${props.routeId}`*/}
            {/*  }*/}
            {/*  target="_blank"*/}
            {/*  endIcon={<DownloadOutlinedIcon />}*/}
            {/*  size="large"*/}
            {/*  // variant="contained"*/}
            {/*>*/}
            {/*  {t("dispatching:openRouteView.actions.drivingReport")}*/}
            {/*</Button>*/}
          </Grid>
          {events.map((e) => {
            let cargoId = "cargoId" in e ? e.cargoId : null;
            return (
              <DriverEventItem
                key={e.id}
                cargoId={cargoId}
                event={e}
                onShowCargo={showCargoModal}
              />
            );
          })}
        </List>
      ) : (
        <LogSkeleton
          label={`${t("dispatching:emptyStates.missingRouteLogItems")}`}
        />
      )}
    </>
  );
};

export default RouteLog;
