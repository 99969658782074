import "./MissingStopTimesAlertDialog.scss";
import { Dialog } from "@mui/material";
import DoraButton from "../../Components/Toolkit/DoraButton";
import { CloseButton } from "../../Components/dispatching-planning/common/close-button";
import { AccountCircleOutlined } from "@mui/icons-material";
import { useSelector } from "../../redux-store";
import { selectClient } from "../../ducks/data/clients/selectors";
import { DateTime } from "luxon";
import useNavigateToCargo from "../../hooks/useNavigateToCargo";
import { useTranslation } from "react-i18next";

const MissingStopTimesAlertDialog = ({
  cargos,
  onClose,
}: {
  cargos: {
    id: string;
    date: string;
    address: string;
    clientId: string | null;
  }[];
  onClose: () => void;
}) => {
  const { t } = useTranslation("calendarView");
  const navigateToCargo = useNavigateToCargo();

  const onAddTimeClick = (cargoId: string) => () => {
    navigateToCargo(cargoId);
  };

  return (
    <Dialog open onClose={onClose}>
      <div className="missing-stop-times-alert-dialog">
        <div className="missing-stop-times-alert-dialog__close-button">
          <CloseButton onClick={onClose} />
        </div>
        <h2>{t("missingStopTimesDialog.title")}</h2>
        {cargos.map((cargo) => {
          const date = DateTime.fromFormat(cargo.date, "yyyy-MM-dd").toFormat(
            "yyyy.MM.dd"
          );
          return (
            <div
              key={cargo.id}
              className="missing-stop-times-alert-dialog__cargo"
            >
              <div>
                <div>
                  {cargo.address} • {date}
                </div>
                <div>
                  {cargo.clientId && (
                    <div className="flex gap-1 items-center">
                      <AccountCircleOutlined className="missing-stop-times-alert-dialog__account-circle" />
                      <ClientName clientId={cargo.clientId} />
                    </div>
                  )}
                </div>
              </div>
              <DoraButton
                variant="primary-filled"
                onClick={onAddTimeClick(cargo.id)}
              >
                {t("missingStopTimesDialog.addTime")}
              </DoraButton>
            </div>
          );
        })}
      </div>
    </Dialog>
  );
};

const ClientName = ({ clientId }: { clientId: string }) => {
  const client = useSelector(selectClient(clientId));
  return <span>{client?.client}</span>;
};

export default MissingStopTimesAlertDialog;
