import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import {
  addRouteWithTrailerAndWaypoint,
  archiveRoute,
} from "../../../ducks/routes";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../redux-store";
import { ControlPoint } from "@mui/icons-material";
import { useActions } from "../../../hooks/useActions";
import useNavigateToRoute from "../../../hooks/useNavigateToRoute";

export default function ArchiveRouteButton(props: {
  routeId: string;
  trailerId?: string | null;
}) {
  const { t } = useTranslation(["dispatching", "components"]);
  const { routeId, trailerId } = props;
  const dispatch = useAppDispatch();
  const actions = useActions({ addRouteWithTrailerAndWaypoint });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const navigateToRoute = useNavigateToRoute();

  const handleArchiveRoute = () => {
    if (trailerId) {
      actions.addRouteWithTrailerAndWaypoint({ routeId }).then((x) => {
        navigateToRoute(x.route.id);
      });
    } else {
      dispatch(archiveRoute({ id: routeId })).then(() => {
        navigateToRoute(null);
      });
    }
  };

  return (
    <>
      <Button
        variant={trailerId ? "outlined" : "contained"}
        color="primary"
        startIcon={<ControlPoint />}
        onClick={handleClick}
      >
        {trailerId
          ? t("dispatching:openRouteView.endRouteAndContinue")
          : t("dispatching:openRouteView.endRoute")}
      </Button>
      <Popper open={open} anchorEl={anchorEl}>
        <Paper>
          <DialogTitle> {t("dispatching:openRouteView.endRoute")}</DialogTitle>
          <DialogContent>
            {t("dispatching:openRouteView.youAreEndingARoute")}
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="primary" onClick={handleClick}>
              {t("components:buttonLabels.cancel")}
            </Button>
            <Button variant="contained" onClick={handleArchiveRoute}>
              {t("components:buttonLabels.confirm")}
            </Button>
          </DialogActions>
        </Paper>
      </Popper>
    </>
  );
}
