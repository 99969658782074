import { Dialog } from "@mui/material";
import { CloseButton } from "../../Components/dispatching-planning/common/close-button";
import DoraButton from "../../Components/Toolkit/DoraButton";
import "./RouteDropDialog.scss";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CalendarViewRouteItemDroppedProps } from "./CalendarViewTrailerRowsSection";
import { useAppDispatch, useSelector } from "../../redux-store";
import { selectRouteViewModel } from "../../ducks/data/route-views/selectors";
import { moveCargo } from "../../ducks/dispatchActions";
import { getRouteViewName } from "../../Components/dispatching-planning/common/RouteName";
import useMoveCargoToNewRoute from "./use-move-cargo-to-new-route";
import { selectCargoView } from "../../ducks/data/cargo-views/selectors";
import AddTimeFirstRouteStopDialog from "./AddTimeFirstRouteStopDialog";

const RouteDropDialog = ({
  onClose,
  itemDroppedProps,
}: {
  onClose: () => void;
  itemDroppedProps: CalendarViewRouteItemDroppedProps;
}) => {
  const {
    cargoId,
    originRouteId,
    dropTrailerId,
    routesForDropTrailerRowFiltered,
    originRouteHasMoreThanOneCargo,
  } = itemDroppedProps;
  const { t } = useTranslation("calendarView");
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [
    shouldShowAddTimeFirstStopDialog,
    setShouldShowAddTimeFirstStopDialog,
  ] = useState(false);
  const dispatch = useAppDispatch();
  const { moveCargoToNewRoute } = useMoveCargoToNewRoute();

  const droppedCargoView = useSelector(selectCargoView(cargoId));

  if (!droppedCargoView) {
    throw new Error(`Cargo view not found ${cargoId}`);
  }

  const onAddToRouteClick = (routeId: string) => {
    setRequestInProgress(true);
    dispatch(
      moveCargo({
        sourceRouteId: originRouteId,
        destRouteId: routeId,
        cargoId: cargoId,
      })
    )
      .then(() => {
        onClose();
      })
      .finally(() => {
        setRequestInProgress(false);
      });
  };

  const onCreateNewRouteClick = () => {
    if (!droppedCargoView.firstStop.time) {
      setShouldShowAddTimeFirstStopDialog(true);
      return;
    }
    setRequestInProgress(true);
    moveCargoToNewRoute({
      dropTrailerId,
      originRouteId,
      cargoId,
      originRouteHasMoreThanOneCargo,
    })
      .then(() => {
        onClose();
      })
      .finally(() => {
        setRequestInProgress(false);
      });
  };

  const onAddTimeFirstRouteStopDialogClose = (success?: boolean) => {
    setShouldShowAddTimeFirstStopDialog(false);
    if (success) {
      onClose();
    }
  };

  return (
    <>
      {shouldShowAddTimeFirstStopDialog && (
        <AddTimeFirstRouteStopDialog
          onClose={onAddTimeFirstRouteStopDialogClose}
          cargoId={droppedCargoView.id}
          originRouteId={originRouteId}
          dropTrailerId={dropTrailerId}
          stopAddress={`${droppedCargoView.firstStop.city} ${droppedCargoView.firstStop.postcode}`}
        />
      )}
      <Dialog open onClose={onClose}>
        <div className="route-drop-dialog">
          <div className={"route-drop-dialog__close-button"}>
            <CloseButton onClick={onClose} disabled={requestInProgress} />
          </div>
          <h2>{t("dropDialog.title")}</h2>
          <div className="route-drop-dialog__text">{t("dropDialog.text")}</div>
          <div className="flex flex-col gap-2 px-6">
            {routesForDropTrailerRowFiltered.map((id, i) => (
              <div key={id}>
                <DoraButton
                  disabled={requestInProgress}
                  variant="primary"
                  autoFocus={i === 0}
                  onClick={() => onAddToRouteClick(id)}
                >
                  <RouteName routeId={id} />
                </DoraButton>
              </div>
            ))}
            <div>
              <DoraButton
                disabled={requestInProgress}
                onClick={onCreateNewRouteClick}
                variant="primary-filled"
              >
                {t("dropDialog.createNewRoute")}
              </DoraButton>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

const RouteName = ({ routeId }: { routeId: string }) => {
  const routeView = useSelector(selectRouteViewModel(routeId));
  const name = useMemo(() => {
    let n = getRouteViewName(
      routeView ? { ...routeView, hasCargos: true } : undefined
    );
    if (!n && routeView) {
      const start = [routeView.firstStop?.city, routeView.firstStop?.postcode];
      const end = [routeView.lastStop?.city, routeView.lastStop?.postcode];
      n = [start, end]
        .map((x) => x.join(" "))
        .filter((s) => !!s)
        .join(" - ");
    }
    return n;
  }, [routeView]);

  return <span>{name}</span>;
};

export default RouteDropDialog;
