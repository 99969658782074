import * as React from "react";
import { useEffect, useState } from "react";
import { Button, IconButton, IconButtonProps } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import DeleteForeverOutlined from "@mui/icons-material/DeleteForeverOutlined";
import { useTranslation } from "react-i18next";
import { MuiTooltip } from "./nav/nav-styles";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CheckIcon from "@mui/icons-material/Check";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import { omit } from "lodash";
import SyncIcon from "@mui/icons-material/Sync";

export const AddIconButton = (
  props: IconButtonProps & { label: string; color: string }
) => {
  return (
    <IconButton
      aria-label={props.label}
      {...props}
      disableFocusRipple
      disableRipple
      color={props.color}
    >
      <MuiTooltip title={props.label} placement="top">
        <AddCircleOutlineOutlinedIcon />
      </MuiTooltip>
    </IconButton>
  );
};

export const UnarchiveIcon = (
  props: IconButtonProps & { tooltipTitle: string }
) => {
  const { tooltipTitle, ...rest } = props;
  return (
    <IconButton {...rest}>
      <MuiTooltip title={tooltipTitle} placement="top">
        <CheckOutlinedIcon fontSize="small" />
      </MuiTooltip>
    </IconButton>
  );
};

export const ArchiveIcon = (
  props: IconButtonProps & { tooltipTitle: string }
) => {
  const { tooltipTitle, ...rest } = props;
  return (
    <IconButton {...rest}>
      <MuiTooltip title={tooltipTitle} placement="top">
        <Inventory2OutlinedIcon fontSize="small" />
      </MuiTooltip>
    </IconButton>
  );
};

export const EditIconButton = (props: IconButtonProps) => {
  const { t } = useTranslation("components");
  return (
    <IconButton aria-label={t("buttonLabels.edit")} {...props}>
      <MuiTooltip title={t("buttonLabels.edit")} placement="top">
        <EditOutlinedIcon fontSize="small" />
      </MuiTooltip>
    </IconButton>
  );
};

export const ExportIconButton = (props: IconButtonProps) => {
  const { t } = useTranslation("components");
  return (
    <IconButton aria-label={t("buttonLabels.edit")} {...props}>
      <MuiTooltip title={t("buttonLabels.edit")} placement="top">
        <EditOutlinedIcon fontSize="small" />
      </MuiTooltip>
    </IconButton>
  );
};

export const DeleteIconButton = (props: IconButtonProps) => {
  const { t } = useTranslation("components");
  return (
    <IconButton aria-label={t("buttonLabels.delete")} color="error" {...props}>
      <MuiTooltip title={t("buttonLabels.delete")} placement="top">
        <DeleteOutlinedIcon fontSize="small" />
      </MuiTooltip>
    </IconButton>
  );
};

export const DeletOutlinedCrossIconButton = (props: IconButtonProps) => {
  const { t } = useTranslation("components");
  return (
    <IconButton aria-label={t("buttonLabels.delete")} {...props}>
      <MuiTooltip title={t("buttonLabels.delete")} placement="top">
        <DeleteForeverOutlined fontSize="small" />
      </MuiTooltip>
    </IconButton>
  );
};

export const MoveUpIconButton = (props: IconButtonProps) => {
  const { t } = useTranslation("components");
  return (
    <IconButton aria-label={t("buttonLabels.moveUp")} {...props}>
      <MuiTooltip title={t("buttonLabels.moveUp")} placement="top">
        <ExpandLessOutlinedIcon fontSize="small" />
      </MuiTooltip>
    </IconButton>
  );
};

export const MoveDownIconButton = (props: IconButtonProps) => {
  const { t } = useTranslation("components");
  return (
    <IconButton aria-label={t("buttonLabels.moveDown")} {...props}>
      <MuiTooltip title={t("buttonLabels.moveDown")} placement="top">
        <ExpandMoreOutlinedIcon fontSize="small" />
      </MuiTooltip>
    </IconButton>
  );
};

const handleCopy = (
  event: React.MouseEvent<HTMLButtonElement>,
  entryText: string
) => {
  event.stopPropagation();
  navigator.clipboard.writeText(entryText);
};

export const CopyIconButton = (
  props: IconButtonProps & {
    label: string | null;
    title: string | null;
    noPadding: boolean | null;
  }
) => {
  const { t } = useTranslation("components");
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setCopied(false);
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, [copied]);

  return (
    <IconButton
      sx={{ padding: props.noPadding ? "0px" : "8px" }}
      aria-label={props.title}
      {...omit(props, "noPadding")}
      onClick={(e: any) => {
        handleCopy(e, `${props.label}`);
        setCopied(true);
      }}
    >
      {copied ? (
        <MuiTooltip
          title={t("buttonLabels.copied")}
          placement="top"
          leaveDelay={3000}
        >
          <CheckIcon color="success" fontSize="small" />
        </MuiTooltip>
      ) : (
        <MuiTooltip title={props.title} placement="top">
          <ContentCopyIcon fontSize="small" />
        </MuiTooltip>
      )}
    </IconButton>
  );
};

export const OutlinedIconButton = React.forwardRef<
  HTMLButtonElement,
  {
    text: string;
    icon: React.ReactElement;
    onClick?: (e: any) => void;
    active?: boolean;
    disabled?: boolean;
  }
>((props, ref) => {
  return (
    <MuiTooltip title={props.text} placement="top">
      <IconButton
        aria-label={props.text}
        size="small"
        ref={ref}
        sx={{
          "&.MuiIconButton-root": {
            border: "solid lightgrey 1px",
            borderColor:
              props.active && !props.disabled ? "primary.main" : "inherit",
            backgroundColor:
              props.active && !props.disabled ? "primary.lightened" : "inherit",
            borderRadius: 0,
            color: props.active && !props.disabled ? "blue" : "grey",
            ":hover": {
              border: "solid lightgrey 1px",
              borderRadius: 0,
              backgroundColor: "lightgrey",
            },
          },
        }}
        onClick={props.onClick}
        disabled={props.disabled}
      >
        {props.icon}
      </IconButton>
    </MuiTooltip>
  );
});

export const ContainedIconButton = (props: {
  text: string;
  icon: React.ReactElement;
  onClick?: () => void;
}) => {
  return (
    <MuiTooltip title={props.text} placement="top">
      <IconButton
        aria-label={props.text}
        size="small"
        sx={{
          "&.MuiIconButton-root": {
            ml: 0.2,
            mr: 0.2,
            border: "solid 1px",
            borderColor: "primary.main",
            borderRadius: 0,
            color: "white",
            backgroundColor: "primary.main",
            ":hover": {
              border: "solid 1px",
              backgroundColor: "primary.dark",
              borderColor: "primary.dark",
            },
          },
        }}
        onClick={props.onClick}
      >
        {props.icon}
      </IconButton>
    </MuiTooltip>
  );
};

export const ContainedButton = (props: {
  text: string;
  onClick: () => void;
}) => {
  return (
    <Button
      variant="contained"
      size="small"
      sx={{
        px: 2.2,
        py: 0.58,
      }}
      onClick={props.onClick}
    >
      {props.text}
    </Button>
  );
};

export const SyncIconButton = (
  props: IconButtonProps & { tooltipTitle: string }
) => {
  const { tooltipTitle, ...rest } = props;
  return (
    <IconButton {...rest}>
      <MuiTooltip title={tooltipTitle} placement="top">
        <SyncIcon color="primary" />
      </MuiTooltip>
    </IconButton>
  );
};
