import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { changeSelectedIntervalAndLoadData } from "../../ducks/data/calendar-view";
import { useAppDispatch, useSelector } from "../../redux-store";
import { selectViewTimeInterval } from "../../ducks/data/calendar-view/selectors";
import { debounce } from "lodash";
import { useDragLayer } from "react-dnd";
import { CALENDAR_VIEW_DRAGGABLE_CARGO_STOP } from "./constants";
import { DateTime } from "luxon";
import { CalendarViewDrawnStop } from "./interfaces";

type CalendarViewControlsContextType = {
  zoomFactor: number;
  increaseZoom: () => void;
  decreaseZoom: () => void;
  colWidth: number;
  setShowCollisionsMultipleRows: (value: boolean) => void;
  showCollisionsMultipleRows: boolean;
  viewTimeInterval: string;
  onTimeSelectionClick: (interval: string) => void;
  showCurrentMomentLine: boolean;
  setShowCurrentMomentLine: (value: boolean) => void;
  rowSectionHeights: { [trailerId: string]: number };
  setRowSectionHeights: (value: { [trailerId: string]: number }) => void;
  moveSingleStopEnabled: boolean;
  setMoveSingleStopEnabled: (value: boolean) => void;
  hoverPosition: DateTime | null;
};

let colWidthOutsideScope: number;

const CalendarViewControlsContext = createContext<
  CalendarViewControlsContextType | undefined
>(undefined);

export const CalendarViewControlsProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [zoomFactor, setZoomFactor] = useState(1);
  const [showCollisionsMultipleRows, setShowCollisionsMultipleRows] =
    useState(false);
  const [showCurrentMomentLine, setShowCurrentMomentLine] = useState(true);
  const viewTimeInterval = useSelector(selectViewTimeInterval);
  const [moveSingleStopEnabled, setMoveSingleStopEnabled] = useState(false);
  const [rowSectionHeights, setRowSectionHeights] = useState<{
    [trailerId: string]: number;
  }>({});
  const [hoverPosition, setHoverPosition] = useState<DateTime | null>(null);

  const updateHoverPosition = debounce(
    (diffInPx: number, initialDateTime: DateTime) => {
      const diffInHours = diffInPx / colWidthOutsideScope;
      let dateTime = initialDateTime.plus({ hours: diffInHours });
      let min = dateTime.minute;
      const minModFive = min % 5;
      if (minModFive !== 0) {
        if (minModFive < 3) {
          min = min - minModFive;
        } else {
          min = min + (5 - minModFive);
        }
        dateTime = dateTime.set({ minute: min });
      }
      setHoverPosition(dateTime);
    },
    10
  );

  useDragLayer((monitor) => {
    if (monitor.isDragging()) {
      if (monitor.getItemType() === CALENDAR_VIEW_DRAGGABLE_CARGO_STOP) {
        const diffFromInitialOffset = monitor.getDifferenceFromInitialOffset();
        if (!diffFromInitialOffset) {
          return;
        }
        const { drawnStop } = monitor.getItem() as {
          drawnStop: CalendarViewDrawnStop;
        };
        const diffInPx = diffFromInitialOffset.x;

        updateHoverPosition(diffInPx, drawnStop.dateTime);
      }
    } else {
      setHoverPosition(null);
    }
  });

  const dispatch = useAppDispatch();

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Alt") {
        setMoveSingleStopEnabled(true);
      }
    },
    [setMoveSingleStopEnabled]
  );

  const onKeyUp = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Alt") {
        setMoveSingleStopEnabled(false);
      }
    },
    [setMoveSingleStopEnabled]
  );

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);
    document.addEventListener("keyup", onKeyUp);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("keyup", onKeyUp);
    };
  }, [onKeyDown, onKeyUp]);

  const increaseZoom = () => {
    setZoomFactor((prev) => prev + 0.4);
  };

  const decreaseZoom = () => {
    setZoomFactor((prev) => prev - 0.4);
  };

  const onTimeSelectionClick = (interval: string) => {
    setZoomFactor(1);
    dispatch(changeSelectedIntervalAndLoadData(interval));
  };

  const colWidth = useMemo(
    () => Math.ceil((zoomFactor * 55) / (viewTimeInterval === "week" ? 7 : 1)),
    [zoomFactor, viewTimeInterval]
  );
  colWidthOutsideScope = colWidth;

  return (
    <CalendarViewControlsContext.Provider
      value={{
        zoomFactor,
        increaseZoom,
        decreaseZoom,
        colWidth,
        showCollisionsMultipleRows,
        setShowCollisionsMultipleRows,
        viewTimeInterval,
        onTimeSelectionClick,
        moveSingleStopEnabled,
        setMoveSingleStopEnabled,
        showCurrentMomentLine,
        setShowCurrentMomentLine,
        rowSectionHeights,
        setRowSectionHeights,
        hoverPosition,
      }}
    >
      {children}
    </CalendarViewControlsContext.Provider>
  );
};

export const useCalendarViewControls = () => {
  const context = useContext(CalendarViewControlsContext);
  if (!context) {
    throw new Error(
      "useCalendarViewControls must be used within a CalendarViewControlsProvider"
    );
  }
  return context;
};
