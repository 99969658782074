import { useState } from "react";
import SavedLocation from "./SavedLocation";
import { EditIconButton, DeletOutlinedCrossIconButton } from "../../buttons";
import { Apartment, Star } from "@mui/icons-material";
import { ConfirmDeleteLocationDialog } from "./ConfirmDeleteLocationDialog";
import "./SavedLocationRow.css";
import { useActions } from "../../../hooks/useActions";
import {
  loadSavedLocations,
  deleteSavedLocation,
} from "../../../ducks/data/saved-locations";

const SavedLocationRow = ({
  location,
  onEditClick,
}: {
  location: SavedLocation;
  onEditClick: () => void;
}) => {
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const actions = useActions({
    deleteSavedLocation,
    loadSavedLocations,
  });

  const onDeleteButtonClick = () => {
    setIsDeleteConfirmationOpen(true);
  };

  const onConfirmDeleteButtonClick = () => {
    actions.deleteSavedLocation(location).then(() => {
      setIsDeleteConfirmationOpen(false);
      actions.loadSavedLocations();
    });
  };

  return (
    <>
      {isDeleteConfirmationOpen && (
        <ConfirmDeleteLocationDialog
          locationName={location.customName}
          onContinueClick={onConfirmDeleteButtonClick}
          onCancelClick={() => setIsDeleteConfirmationOpen(false)}
        />
      )}
      <div className="saved-location-row__container">
        <div className="saved-location-row__icon-circle">
          <div className="saved-location-row__icon">
            {location.type === "FAVORITE" ? <Star /> : <Apartment />}
          </div>
        </div>
        <div className="saved-location-row__text">
          <div>{location.customName}</div>
          <div>{location.place.address}</div>
        </div>
        <div className="saved-location-row__actions">
          <EditIconButton onClick={onEditClick}></EditIconButton>
          <DeletOutlinedCrossIconButton
            onClick={onDeleteButtonClick}
          ></DeletOutlinedCrossIconButton>
        </div>
      </div>
    </>
  );
};

export default SavedLocationRow;
