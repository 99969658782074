import { useAppDispatch } from "../../redux-store";
import { addRoute, updateRouteTrailer } from "../../ducks/routes";
import { moveCargo, updateFirstStopTime } from "../../ducks/dispatchActions";
import { useCallback } from "react";

const useMoveCargoToNewRoute = () => {
  const dispatch = useAppDispatch();

  const moveCargoToNewRoute = useCallback(
    async (input: {
      dropTrailerId: string;
      originRouteId: string | null;
      cargoId: string;
      originRouteHasMoreThanOneCargo: boolean;
    }) => {
      const {
        dropTrailerId,
        originRouteId,
        cargoId,
        originRouteHasMoreThanOneCargo,
      } = input;
      if (originRouteHasMoreThanOneCargo) {
        return dispatch(
          updateRouteTrailer({
            id: originRouteId!,
            trailer_id: dropTrailerId,
          })
        );
      }
      const data = await dispatch(addRoute({ trailerId: dropTrailerId }));
      return dispatch(
        moveCargo({
          sourceRouteId: originRouteId,
          destRouteId: (data.payload as any).id,
          cargoId: cargoId,
        })
      );
    },
    [dispatch]
  );

  const updateFirstStopAndMoveCargoToNewRoute = useCallback(
    async (input: {
      dropTrailerId: string;
      originRouteId: string | null;
      cargoId: string;
      originRouteHasMoreThanOneCargo: boolean;
      firstStopTime: string;
    }) => {
      const {
        dropTrailerId,
        originRouteId,
        cargoId,
        originRouteHasMoreThanOneCargo,
        firstStopTime,
      } = input;
      await dispatch(updateFirstStopTime({ cargoId, firstStopTime }));
      await moveCargoToNewRoute({
        dropTrailerId,
        originRouteId,
        cargoId,
        originRouteHasMoreThanOneCargo,
      });
    },
    [dispatch, moveCargoToNewRoute]
  );

  return { moveCargoToNewRoute, updateFirstStopAndMoveCargoToNewRoute };
};

export default useMoveCargoToNewRoute;
