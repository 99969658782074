import { useCallback } from "react";
import { useStableNavigate } from "../Components/StableNavigateContext";

const useNavigateToCargo = () => {
  const navigate = useStableNavigate();
  return useCallback(
    (cargoId: string | null) => {
      navigate({ hash: `#cargo=${cargoId}` });
    },
    [navigate]
  );
};

export default useNavigateToCargo;
