import { useCalendarViewControls } from "./CalendarViewControlContext";
import { useEffect, useMemo } from "react";
import clsx from "clsx";
import "./CalendarViewTimelineTopBar.scss";

const fiveAmIndicatorCssId = "five-am-indicator";

export const CalendarViewTimelineTopBar = ({ hide }: { hide?: boolean }) => {
  const { viewTimeInterval, colWidth } = useCalendarViewControls();

  useEffect(() => {
    if (hide) {
      return;
    }
    const bumper = document.getElementById(
      "calendar-view-left-section__top-bumper"
    );
    const fiveAmIndicator = document.getElementById(fiveAmIndicatorCssId);
    const scrollContainer = document.getElementById(
      "calendar-view-page__scroll-container"
    );
    if (!bumper || !scrollContainer || !fiveAmIndicator) {
      return;
    }

    if (viewTimeInterval === "day") {
      const bumperStart = bumper.getBoundingClientRect().x;
      const bumperWidth = bumper.getBoundingClientRect().width;
      const fiveAmIndicatorStart = fiveAmIndicator.getBoundingClientRect().x;
      scrollContainer.scrollBy({
        left: fiveAmIndicatorStart - bumperStart - bumperWidth,
        behavior: "smooth",
      });
    } else {
      scrollContainer.scrollTo({ left: 0, behavior: "smooth" });
    }
  }, [hide, viewTimeInterval]);

  const times: { value: string; shouldShow: boolean }[] = useMemo(() => {
    const timesArray = [];
    const loopCount = viewTimeInterval === "day" ? 25 : 24;
    for (let t = 0; t < loopCount; t++) {
      const val = t < 10 ? `0${t}:00` : `${t}:00`;
      let shouldShow = true;
      if (viewTimeInterval === "week") {
        if (colWidth < 17) {
          shouldShow = t % 12 === 0;
        } else if (colWidth < 29) {
          shouldShow = t % 6 === 0;
        } else if (colWidth < 70) {
          shouldShow = t % 3 === 0;
        } else {
          shouldShow = true;
        }
        if (val === "24:00" && viewTimeInterval === "week") {
          shouldShow = false;
        }
      }
      timesArray.push({
        value: val,
        shouldShow,
      });
    }
    return timesArray;
  }, [viewTimeInterval, colWidth]);

  const days: string[] = useMemo(() => {
    return viewTimeInterval === "day"
      ? [""]
      : [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ];
  }, [viewTimeInterval]);

  const classes = clsx("timeline-top-bar", {
    "no-days": viewTimeInterval === "day",
  });

  return (
    <div
      className="timeline-top-bar-wrapper"
      style={{
        zIndex: hide ? -1 : 1,
        position: hide ? "relative" : "sticky",
        height: hide ? 0 : "auto",
      }}
    >
      <div className={classes}>
        {days.map((d, i) => (
          <div key={i}>
            <div className="timeline-top-bar__day-vertical-line"></div>
            <div style={{ marginLeft: 4 }}>{d}</div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: `${colWidth}px repeat(${times.length - 1}, ${colWidth}px)`,
              }}
            >
              {times.map((t) => {
                const classes = clsx("timeline-top-bar-item-wrapper", {
                  "timeline-top-bar-item-wrapper--hidden": !t.shouldShow,
                });
                return (
                  <div
                    key={t.value}
                    className={classes}
                    id={t.value === "05:00" ? fiveAmIndicatorCssId : ""}
                  >
                    {t.value}
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CalendarViewTimelineTopBar;
