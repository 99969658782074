import { FC, memo, ComponentType, SVGProps } from "react";
import AcUnitIcon from "@mui/icons-material/AcUnit";
// import OpacityOutlinedIcon from "@mui/icons-material/OpacityOutlined";
import Crop32OutlinedIcon from "@mui/icons-material/Crop32Outlined";
import PrecisionManufacturingOutlinedIcon from "@mui/icons-material/PrecisionManufacturingOutlined";
import WaterDropOutlinedIcon from "@mui/icons-material/WaterDropOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import LocalFloristIcon from "@mui/icons-material/LocalFlorist";
import CarRepairIcon from "@mui/icons-material/CarRepair";
import SailingIcon from "@mui/icons-material/Sailing";
import LocalHospitalOutlinedIcon from "@mui/icons-material/LocalHospitalOutlined";
import PetsOutlinedIcon from "@mui/icons-material/PetsOutlined";
import SvgIcon, { SvgIconTypeMap } from "@mui/material/SvgIcon";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import BlurCircularIcon from "@mui/icons-material/BlurCircular";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import TwentyFootIcon from "../../assets/icons/20fod.svg?react";
import TwentyFootHighCubeIcon from "../../assets/icons/20fod-high.svg?react";
import FortyFootIcon from "../../assets/icons/40fod.svg?react";
import FortyFootHighCubeIcon from "../../assets/icons/40fod-high.svg?react";
import CoilIcon from "../../assets/icons/coil.svg?react";
import BulkIcon from "../../assets/icons/bulkgods.svg?react";
import MachineIcon from "../../assets/icons/maskiner.svg?react";
import PackageIcon from "../../assets/icons/pakker.svg?react";
import EmptyIcon from "../../assets/icons/pakker2.svg?react";
import ParcelIcon from "../../assets/icons/pallegods.svg?react";
import LTLIcon from "../../assets/icons/stykgods.svg?react";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { CargoType } from "../../helpers/types";

interface Props {
  type: CargoType;
  className?: string;
}

const createMuiIconComponent =
  (
    Component: OverridableComponent<SvgIconTypeMap>
  ): ComponentType<{ className?: string }> =>
  (props) => <Component fontSize="small" className={props.className} />;

const createSvgIconComponent =
  (
    Component: ComponentType<SVGProps<SVGSVGElement>>
  ): ComponentType<{ className?: string }> =>
  (props) => (
    <SvgIcon fontSize="small" className={props.className}>
      <Component
        fill="rgba(255, 255, 255, 0)"
        stroke="currentcolor"
        strokeWidth="12.6"
      />
    </SvgIcon>
  );

const iconMap: Record<CargoType, ComponentType<{ className?: string }>> = {
  MISCELLANEOUS: createSvgIconComponent(EmptyIcon),
  "4_AXLE_RIGID_HGV": createSvgIconComponent(EmptyIcon),
  "4_AXLE_CRANE": PrecisionManufacturingOutlinedIcon,
  "7_AXLE_CENTRE_TRAILER": createSvgIconComponent(EmptyIcon),
  "7_AXLE_TRAILER": createSvgIconComponent(EmptyIcon),
  "7_AXLE_CRANE": PrecisionManufacturingOutlinedIcon,
  "3_AXLE_TRUCK": createSvgIconComponent(EmptyIcon),
  "5_AXLE_TIPPER_TRAILER": createSvgIconComponent(EmptyIcon),
  "7_AXLE_TIPPER_TRAILER": createSvgIconComponent(EmptyIcon),
  MODULE: Crop32OutlinedIcon,
  SLUDGE: BlurCircularIcon,
  LIQUID: WaterDropOutlinedIcon,
  CRANE_WORK: PrecisionManufacturingOutlinedIcon,
  CONTAINER: CheckBoxOutlineBlankOutlinedIcon,
  PALLET_GOODS: createSvgIconComponent(ParcelIcon),
  CC: createMuiIconComponent(LocalFloristIcon),
  COOL: createMuiIconComponent(AcUnitIcon),
  FREEZE: createMuiIconComponent(AcUnitIcon),
  // TANKER: createMuiIconComponent(OpacityOutlinedIcon),
  PHARMA: createMuiIconComponent(LocalHospitalOutlinedIcon),
  BOATS: createMuiIconComponent(SailingIcon),
  LIVE_ANIMALS: createMuiIconComponent(PetsOutlinedIcon),
  CARS: createMuiIconComponent(CarRepairIcon),
  "20_FEET": createSvgIconComponent(TwentyFootIcon),
  "20_FEET_HIGH_CUBE": createSvgIconComponent(TwentyFootHighCubeIcon),
  "40_FEET": createSvgIconComponent(FortyFootIcon),
  "40_FEET_HIGH_CUBE": createSvgIconComponent(FortyFootHighCubeIcon),
  COIL: createSvgIconComponent(CoilIcon),
  BULK_CARGO: createSvgIconComponent(BulkIcon),
  CONCRETE: createSvgIconComponent(BulkIcon),
  MACHINES: createSvgIconComponent(MachineIcon),
  GENERAL_CARGO: createSvgIconComponent(LTLIcon),
  PACKAGES: createSvgIconComponent(PackageIcon),
  BIG_BAGS: LocalMallOutlinedIcon,
};

const fallbackIcon = createMuiIconComponent(MoreHorizOutlinedIcon);

export const CargoTypeAvatar: FC<Props> = memo((props) => {
  const { type, ...rest } = props;
  const Component = iconMap[type] || fallbackIcon;
  return <Component {...rest} />;
});
