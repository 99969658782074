import { CalendarViewRouteData } from "../../ducks/data/calendar-view/types";
import { useAppDispatch, useSelector } from "../../redux-store";
import { selectDrivers } from "../../ducks/data/drivers/selectors";
import {
  addDriver,
  removeDriver,
  updateRouteTrailer,
} from "../../ducks/routes";
import AddDrivers from "../../Components/dispatching-planning/common/AddDrivers";
import React from "react";
import "./CalendarViewRouteDrivers.scss";

const CalendarViewRouteDrivers = ({
  route,
}: {
  route: CalendarViewRouteData;
}) => {
  const dispatch = useAppDispatch();
  const { driversIds } = route;
  const drivers = useSelector(selectDrivers);

  const onDriverClick = (driverId: string, checked: boolean) => {
    const args = { routeId: route.id, driverId };
    dispatch(checked ? addDriver(args) : removeDriver(args))
      .unwrap()
      .then(() => {
        if (checked) {
          const driver = drivers.find((d) => d.id === driverId);
          if (!driver) {
            return;
          }
          if (!route.trailerId && driver.defaultTrailerId) {
            dispatch(
              updateRouteTrailer({
                id: route.id,
                trailer_id: driver.defaultTrailerId,
              })
            );
          }
        }
      });
  };

  return (
    <div className="calendar-view-route-drivers">
      <AddDrivers
        onDriverClick={onDriverClick}
        routeDrivers={driversIds}
        routeId={route.id}
      />
      {driversIds
        .map((id) => {
          const driver = drivers.find((d) => d.id === id);
          return driver
            ? `${driver.firstName} ${driver.lastName.charAt(0)}.`
            : "";
        })
        .join(", ")}
    </div>
  );
};

export default CalendarViewRouteDrivers;
