import { useDrag } from "react-dnd";
import { CalendarViewDrawnStop } from "./interfaces";
import { useStableNavigate } from "../../Components/StableNavigateContext";
import { CALENDAR_VIEW_DRAGGABLE_CARGO_STOP } from "./constants";
import { useSelector } from "../../redux-store";
import { selectClient } from "../../ducks/data/clients/selectors";
import clsx from "clsx";
import { selectIsStopCompleted } from "../../ducks/data/driver-sessions/selectors";

const CalendarViewStop = ({
  drawnStop,
  leftOffset,
  cargoDescription,
  cargoInvoiceable,
  cargoFirstStopArrived,
  cargoAllStopsDeparted,
  cargoFirstStopId,
  clientId,
}: {
  drawnStop: CalendarViewDrawnStop;
  leftOffset: number;
  cargoDescription: string;
  cargoInvoiceable: boolean;
  cargoFirstStopArrived: boolean;
  cargoAllStopsDeparted: boolean;
  cargoFirstStopId: string;
  clientId: string | null;
}) => {
  const navigate = useStableNavigate();

  let width = drawnStop.width - 4; // -4 to offset the route border
  if (width < 1) {
    width = 1;
  }

  const onClick = () => {
    navigate({ hash: `cargo=${drawnStop.cargoId}` });
  };

  const [{ isDragging }, drag] = useDrag({
    type: CALENDAR_VIEW_DRAGGABLE_CARGO_STOP,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => {
      return { drawnStop };
    },
  });

  const client = useSelector(selectClient(clientId));

  const firstRowText = [drawnStop.postcode, drawnStop.cityName].join(" ");

  const isCargoFirstStopCompleted = useSelector(
    selectIsStopCompleted(cargoFirstStopId)
  );

  const classes = clsx("calendar-view-stop", {
    "calendar-view-stop--invoiceable": cargoInvoiceable,
    "calendar-view-stop--all-stops-departed": cargoFirstStopArrived,
    "calendar-view-stop--started":
      !cargoInvoiceable &&
      !cargoAllStopsDeparted &&
      (cargoFirstStopArrived || isCargoFirstStopCompleted),
  });

  return (
    <>
      <div
        ref={drag}
        onClick={onClick}
        className={classes}
        style={{
          // background: drawnStop.color,
          left: drawnStop.startPosX - leftOffset,
          width,
          border: `0.1px solid gray`,
          opacity: isDragging ? 0.5 : 1,
        }}
      >
        <div className="calendar-view-stop__first-row">
          <span style={{ background: drawnStop.color }}>{firstRowText}</span>
        </div>
        <div className="calendar-view-stop__second-row">
          <div>{client?.client}</div>
          <div>{cargoDescription}</div>
        </div>
      </div>
    </>
  );
};

export default CalendarViewStop;
