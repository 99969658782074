import React, { useMemo, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { CargoTypeAvatar } from "../../UpcomingPage/cargo-type-avatar";
import { useSelector } from "../../../redux-store";
import { NewNoteDot } from "./NoteDot";
import { formatCustomDateString } from "../../../helpers/date-helper";
import { useTranslation } from "react-i18next";
import { useL10n } from "../../../l10n";
import { useAppDispatch } from "../../../redux-store";
import * as dispatchingActions from "../../../ducks/app/dispatching";
import * as dispatchingSelectors from "../../../ducks/app/dispatching/selectors";
import { Identifier } from "typescript";
import * as cargoViewSelectors from "../../../ducks/data/cargo-views/selectors";
import { Stop, CargoView } from "../../../ducks/data/cargo-views/types";
import * as actions from "../../../ducks/app/dispatching";
import clsx from "clsx";
import CheckIcon from "@mui/icons-material/Check";
import { MuiTooltip } from "../../../Components/nav/nav-styles";
import {
  selectCargoTags,
  selectHideCargoDescription,
  selectHideInvoiceRef,
  selectHideLoadmeters,
} from "../../../ducks/data/org-settings/selectors";
import { useFeature } from "../../../hooks";
import { selectClient } from "../../../ducks/data/clients/selectors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser } from "@fortawesome/free-regular-svg-icons";
import "./cargo-card.scss";
import Tooltip from "@mui/material/Tooltip";
import { selectIsStopCompleted } from "../../../ducks/data/driver-sessions/selectors";

const getLocationText = (
  cargo: Stop | null,
  isWide: boolean | undefined
): string => {
  if (!cargo) return "";
  const contrPost = [cargo.countryCode, cargo.postcode]
    .filter((t) => !!t)
    .join("-");
  if (cargo.city && (isWide || !cargo.postcode)) {
    return contrPost ? contrPost + " " + cargo.city : cargo.city;
  }
  return contrPost;
};
const formatDateTime = (cargo: Stop | null, timeMinimised = false): string => {
  let d = cargo?.date ? formatCustomDateString(cargo?.date, "dd/MM") : "--/--";
  if (cargo?.time) {
    if (!timeMinimised) {
      d += ` (${cargo?.time})`;
    } else {
      d += ` (${cargo?.time.slice(0, 2)}:${cargo?.time.slice(3, 5)})`;
    }
  }
  return d;
};

interface Props {
  cargo: CargoView;
  onClick?: (cargoId: string) => void;
}

type CargoIdProps = {
  cargoId: string;
  onClick?: (cargoId: string) => void;
};

type DraggableCargoProps = CargoIdProps & {
  routeId: string | null;
  isPlaceholder: boolean;
};
type DragItem = { cargoId: string; routeId: string | null };

export const CARGO_LIST_DRAGGABLE_CARGO = "CARGO_LIST_DRAGGABLE_CARGO";

export const DraggableCargo = (props: DraggableCargoProps) => {
  const { cargoId, routeId } = props;
  const cargo =
    useSelector(cargoViewSelectors.selectCargoView(props.cargoId)) || null;
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const draggedCargoId = useSelector(
    (state) => dispatchingSelectors.selectDraggedCargoId(state) === cargoId
  );
  const [, drag] = useDrag(
    () => ({
      type: CARGO_LIST_DRAGGABLE_CARGO,
      item: () => {
        dispatch(dispatchingActions.beginDragCargo({ cargoId, routeId }));
        return { cargoId, routeId };
      },
      end: () => {
        dispatch(dispatchingActions.endDrag());
      },
    }),
    [cargoId, routeId]
  );
  const [, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >(() => {
    return {
      accept: [CARGO_LIST_DRAGGABLE_CARGO, "STOP"],
      drop: () => {
        dispatch(actions.commit());
      },
      hover: (item, monitor) => {
        return;
        // if (!ref.current) {
        //   return;
        // }
        // if (cargoId === item.cargoId) {
        //   return;
        // }
        // const clientOffset = monitor.getClientOffset();
        // if (clientOffset === null) {
        //   return;
        // }
        // const hoverBoundingRect = ref.current?.getBoundingClientRect();
        // const hoverMiddleY =
        //   (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        // const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        // const position = hoverClientY < hoverMiddleY ? "ABOVE" : "BELOW";
        // dispatch(
        //   dispatchingActions.hover({
        //     destRouteId: routeId,
        //     hoverCargoId: cargoId,
        //     position,
        //   })
        // );
      },
    };
  }, [cargoId]);
  drag(drop(ref));
  return (
    <div ref={ref} style={{ opacity: draggedCargoId ? 0.5 : 1 }}>
      {cargo && !props.isPlaceholder ? (
        <CargoInfo cargo={cargo} onClick={props.onClick} />
      ) : null}
    </div>
  );
};

export const CargoInfoId = (props: CargoIdProps) => {
  const cargo =
    useSelector(cargoViewSelectors.selectCargoView(props.cargoId)) || null;
  return cargo && <CargoInfo cargo={cargo} onClick={props.onClick} />;
};

const NewCargoInfoContents = React.memo(
  (
    props: Props & {
      dateInfo: string;
      dateInfoMinimised: string;
      dimensions: string;
      p: Stop | null;
      d: Stop | null;
    }
  ) => {
    const cargoTagsEnabled = useFeature("cargo-tags");
    const { t } = useTranslation("dispatching");
    const { cargo, dateInfo, dateInfoMinimised, dimensions, p, d } = props;
    const l10n = useL10n();
    const cargoTags = useSelector(selectCargoTags);
    const cargoTag =
      cargoTagsEnabled && cargoTags.find((t) => t.id === cargo?.cargoTagId);
    const onClick = () => {
      props.onClick && props.onClick(props.cargo.id);
    };
    const client = useSelector(selectClient(cargo.clientId));
    const hideLoadmeters = useSelector(selectHideLoadmeters);
    const hideDescription = useSelector(selectHideCargoDescription);
    const hideInvoiceRef = useSelector(selectHideInvoiceRef);
    const isCargoFirstStopCompleted = useSelector(
      selectIsStopCompleted(cargo.firstStop.id)
    );

    const longLocationInfo = useMemo(() => {
      return (
        (getLocationText(p, true) || `${t("cargoCard.startPosition")}`) +
        " - " +
        getLocationText(d, true)
        //  || `${t("cargoCard.destinationUnspecified")}`)
      );
    }, [d, p, t]);
    const shortLocationInfo = useMemo(() => {
      return (
        (getLocationText(p, false) || `${t("cargoCard.startPosition")}`) +
        " - " +
        getLocationText(d, false)
        // ||
        //   `${t("cargoCard.destinationUnspecified")}`)
      );
    }, [d, p, t]);

    return (
      <div className="flex">
        {cargoTag ? (
          <Tooltip title={cargoTag.description} placement="top">
            <div
              className="cargo-card-border"
              style={{ borderLeftColor: cargoTag.color }}
            ></div>
          </Tooltip>
        ) : (
          <div className="cargo-card-border"></div>
        )}
        <div
          id={cargo.id} /* this is for UI tests */
          className={clsx("cargo-card", {
            "cargo-card--invoiceable": cargo.invoiceable,
            "cargo-card--all-stops-departed": cargo.allStopsDeparted,
            "cargo-card--started":
              !cargo.invoiceable &&
              !cargo.allStopsDeparted &&
              (cargo.firstStopArrived || isCargoFirstStopCompleted),
          })}
          onClick={onClick}
        >
          <div className="cargo-card__icon">
            {cargo.type ? (
              <CargoTypeAvatar type={cargo.type} />
            ) : (
              <div style={{ width: "1.25rem" }}></div>
            )}
          </div>
          <div className="cargo-card__cities-short">{shortLocationInfo}</div>
          <div className="cargo-card__cities-long">{longLocationInfo}</div>
          <div className="cargo-card__dates">
            <span className="cargo-card-date--full">{dateInfo}</span>
            <span className={"cargo-card-date--min"}>{dateInfoMinimised}</span>
          </div>
          <div className="cargo-card__start-place">{p?.place || ""}</div>
          <div className="cargo-card__start-city">{p?.city || ""}</div>
          <div className="cargo-card__stop-place">{d?.place || ""}</div>
          <div className="cargo-card__stop-city">{d?.city || ""}</div>
          <div className="cargo-card__right">
            <div className="cargo-card__dimensions">
              {client ? (
                <div className="cargo-card__client">
                  <FontAwesomeIcon icon={faCircleUser} />
                  {client.client}
                </div>
              ) : (
                ""
              )}
              {!hideLoadmeters && (
                <div>
                  {l10n.formatNumber(cargo.totalLm)}&nbsp;
                  <span className="cargo-card__unit">
                    {t("cargoCard.loadmeterAbbreviation")}
                  </span>
                  {dimensions && (
                    <span className="cargo-card__unit">({dimensions})</span>
                  )}
                </div>
              )}
              {cargo.invoiceRef && !hideInvoiceRef && (
                <div className="cargo-card__invoice-ref">
                  {cargo.invoiceRef}
                </div>
              )}
            </div>
            {cargo.invoiced && (
              <div className="cargo-card__invoiced-icon">
                <MuiTooltip title={t("cargoCard.invoiced")} placement="top">
                  <CheckIcon />
                </MuiTooltip>
              </div>
            )}
          </div>
          {!hideDescription && (
            <div className="cargo-card__bottom">{cargo.description}</div>
          )}
          <NewNoteDot className="cargo-card__note" note={cargo.note} />
        </div>
      </div>
    );
  }
);

export const CargoInfo = (props: Props) => {
  const cargo = props.cargo;

  const p = cargo.firstStop;
  const d = cargo.lastStop;
  const onClick = () => {
    props.onClick && props.onClick(cargo.id);
  };

  const dimensions = useMemo(
    () =>
      cargo
        ? [cargo.length, cargo.width, cargo.height].filter((d) => !!d).join("x")
        : "",
    [cargo]
  );
  const dateInfo = useMemo(
    () => formatDateTime(p) + " - " + formatDateTime(d),
    [d, p]
  );
  const dateInfoMinimised = useMemo(
    () => formatDateTime(p, true) + " - " + formatDateTime(d, true),
    [d, p]
  );

  return (
    <NewCargoInfoContents
      cargo={cargo}
      dimensions={dimensions}
      dateInfo={dateInfo}
      dateInfoMinimised={dateInfoMinimised}
      onClick={onClick}
      p={p}
      d={d}
    />
  );
};
