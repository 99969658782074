import { Dialog } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { CloseButton } from "../../Components/dispatching-planning/common/close-button";
import DoraButton from "../../Components/Toolkit/DoraButton";
import "./AddTimeFirstRouteStopDialog.scss";
import {
  DoraTimeRangePicker,
  DoraTimeRangeValue,
} from "../../Components/Toolkit/DoraTimeRangePicker";
import useMoveCargoToNewRoute from "./use-move-cargo-to-new-route";

const AddTimeFirstRouteStopDialog = ({
  onClose,
  cargoId,
  originRouteId,
  dropTrailerId,
  stopAddress,
}: {
  onClose: (success?: boolean) => void;
  cargoId: string;
  originRouteId: string | null;
  dropTrailerId: string;
  stopAddress: string;
}) => {
  const { t } = useTranslation("calendarView");
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [error, setError] = useState(false);
  const [time, setTime] = useState<DoraTimeRangeValue>({
    start: "",
    end: "",
  });

  const { updateFirstStopAndMoveCargoToNewRoute } = useMoveCargoToNewRoute();

  const onSaveClick = () => {
    if (!time.start) {
      setError(true);
      return;
    }
    setRequestInProgress(true);
    const val = [time.start, time.end].join(" - ");

    updateFirstStopAndMoveCargoToNewRoute({
      dropTrailerId,
      originRouteId,
      cargoId,
      originRouteHasMoreThanOneCargo: false,
      firstStopTime: val,
    })
      .then(() => {
        onClose(true);
      })
      .finally(() => {
        setRequestInProgress(false);
      });
  };

  const onTimeRangeChange = (value: DoraTimeRangeValue) => {
    setError(false);
    setTime(value);
  };

  return (
    <Dialog open onClose={() => onClose()}>
      <div className="add-time-first-route-stop-dialog">
        <div className="add-time-first-route-stop-dialog__close-button">
          <CloseButton onClick={onClose} disabled={requestInProgress} />
        </div>
        <h2 className="p-4 add-time-dialog-border-bottom">
          {t("addTimeFirstRouteStopDialog.title")}
        </h2>
        <div className="px-4 py-6 add-time-dialog-border-bottom">
          <div className="add-time-first-route-stop-dialog__content-address">
            {stopAddress}
          </div>
          <div className="mt-2" style={{ width: 170 }}>
            <DoraTimeRangePicker
              error={error}
              onChange={onTimeRangeChange}
              value={time}
            />
          </div>
          <div className="add-time-first-route-stop-dialog__error">
            {error && t("addTimeFirstRouteStopDialog.error")}
          </div>
        </div>
        <div className="flex p-4 gap-2 items-center justify-end">
          <DoraButton
            variant="primary"
            disabled={requestInProgress}
            onClick={() => onClose()}
          >
            Cancel
          </DoraButton>
          <DoraButton
            variant="primary-filled"
            onClick={onSaveClick}
            disabled={requestInProgress}
          >
            Save
          </DoraButton>
        </div>
      </div>
    </Dialog>
  );
};

export default AddTimeFirstRouteStopDialog;
