import { DateTime } from "luxon";
import { useCalendarViewControls } from "./CalendarViewControlContext";
import { useSelector } from "../../redux-store";
import { selectStartDate } from "../../ducks/data/calendar-view/selectors";
import "./CalendarViewCurrentMomentVerticalLine.scss";

const CalendarViewCurrentMomentVerticalLine = ({
  height,
}: {
  height?: number;
}) => {
  const now = DateTime.now();

  const { colWidth, viewTimeInterval, showCurrentMomentLine } =
    useCalendarViewControls();

  const searchStartDate = useSelector(selectStartDate);

  const isDayView = viewTimeInterval === "day";

  if (!showCurrentMomentLine) {
    return null;
  }

  if (isDayView && now.get("day") !== searchStartDate.get("day")) {
    return null;
  }

  if (!isDayView && now.get("weekYear") !== searchStartDate.get("weekYear")) {
    return null;
  }

  const left = isDayView
    ? colWidth * now.get("hour") + (colWidth * now.get("minute")) / 60
    : colWidth * 24 * (now.get("weekday") - 1) +
      colWidth * now.get("hour") +
      (colWidth * now.get("minute")) / 60;

  return (
    <div
      className="calendar-view-current-moment-vertical-line"
      style={{ left, height, top: isDayView ? 17 : 50 }}
    >
      <div className="calendar-view-current-moment-vertical-line__circle"></div>
    </div>
  );
};

export default CalendarViewCurrentMomentVerticalLine;
