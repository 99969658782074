import { useCalendarViewControls } from "./CalendarViewControlContext";
import React from "react";
import "./CalendarViewRowDayVerticalLines.scss";

const CalendarViewRowDayVerticalLines = () => {
  const { colWidth } = useCalendarViewControls();

  const days = [0, 0, 0, 0, 0, 0, 0];

  return (
    <div className="absolute h-full">
      {days.map((_, i) => (
        <div
          key={i}
          className="calendar-view-day-row-day-vertical-line"
          style={{
            left: colWidth * 24 * i,
          }}
        ></div>
      ))}
    </div>
  );
};

export default CalendarViewRowDayVerticalLines;
