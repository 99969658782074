import { Tooltip } from "@mui/material";
import DoraButton from "../../Components/Toolkit/DoraButton";
import {
  Add,
  Adjust,
  ArrowBack,
  ArrowForward,
  Expand,
  Remove,
  ReportProblem,
} from "@mui/icons-material";
import React, { useMemo, useState } from "react";
import { useAppDispatch, useSelector } from "../../redux-store";
import {
  selectDataForCalendarView,
  selectStartDate,
} from "../../ducks/data/calendar-view/selectors";
import { useCalendarViewControls } from "./CalendarViewControlContext";
import {
  nextIntervalSelected,
  previousIntervalSelected,
} from "../../ducks/data/calendar-view";
import { useTranslation } from "react-i18next";
import MissingStopTimesAlertDialog from "./MissingStopTimesAlertDialog";
import { isNotNull } from "dora-shared";

const CalendarViewPageTopControls = () => {
  const { t } = useTranslation("calendarView");
  const [missingTimesAlertDialogOpen, setMissingTimesAlertDialogOpen] =
    useState(false);
  const dispatch = useAppDispatch();

  const calendarViewData = useSelector(selectDataForCalendarView);
  const searchStartDate = useSelector(selectStartDate);

  const {
    zoomFactor,
    increaseZoom,
    decreaseZoom,
    showCollisionsMultipleRows,
    setShowCollisionsMultipleRows,
    onTimeSelectionClick,
    viewTimeInterval,
    showCurrentMomentLine,
    setShowCurrentMomentLine,
    moveSingleStopEnabled,
    hoverPosition,
  } = useCalendarViewControls();

  const cargosWhereFirstRouteStopTimeIsMissing = useMemo(() => {
    const cargos: {
      id: string;
      date: string;
      address: string;
      clientId: string | null;
    }[] = [];
    for (const trailerWithRoutes of calendarViewData) {
      for (const route of trailerWithRoutes.routes) {
        for (const cargo of route.cargos) {
          for (const stop of cargo.stops) {
            if (
              route.routeStartDate === stop.date &&
              route.routeStartTime === stop.startTime &&
              stop.startTimeAssumed
            ) {
              cargos.push({
                id: cargo.id,
                date: stop.date,
                address: [stop.postcode, stop.cityName]
                  .filter(isNotNull)
                  .join(" - "),
                clientId: cargo.clientId,
              });
              break;
            }
          }
        }
      }
    }
    return cargos;
  }, [calendarViewData]);

  const onIntervalClick = (interval: string) => {
    onTimeSelectionClick(interval);
  };

  const onPreviousClick = () => {
    dispatch(previousIntervalSelected);
  };

  const onNextClick = () => {
    dispatch(nextIntervalSelected);
  };

  return (
    <>
      {missingTimesAlertDialogOpen && (
        <MissingStopTimesAlertDialog
          cargos={cargosWhereFirstRouteStopTimeIsMissing}
          onClose={() => setMissingTimesAlertDialogOpen(false)}
        />
      )}
      <div className="flex justify-between p-4 relative">
        <div className="flex gap-1 items-center" style={{ zIndex: 1 }}>
          <button
            className={
              viewTimeInterval === "day"
                ? "calendar-view-interval-button filled"
                : "calendar-view-interval-button"
            }
            onClick={() => onIntervalClick("day")}
          >
            {t("day")}
          </button>
          <button
            className={
              viewTimeInterval === "week"
                ? "calendar-view-interval-button filled"
                : "calendar-view-interval-button"
            }
            onClick={() => onIntervalClick("week")}
          >
            {t("week")}
          </button>
          <div
            style={{
              marginLeft: 12,
              fontSize: 14,
              color: "var(--primary-600)",
            }}
          >
            {hoverPosition?.toFormat("ccc HH:mm")}
          </div>
        </div>
        <div
          className="flex gap-2 items-center absolute justify-center"
          style={{ left: "50%", transform: "translateX(-50%)" }}
        >
          <Tooltip title={t("previous")} placement="top">
            <div>
              <DoraButton
                variant={"primary"}
                style={{ padding: "7px 10px" }}
                onClick={onPreviousClick}
              >
                <ArrowBack style={{ fontSize: 16 }} />
              </DoraButton>
            </div>
          </Tooltip>
          <div>
            {viewTimeInterval === "day" &&
              searchStartDate.toFormat("dd.MM.yyyy")}
            {viewTimeInterval === "week" &&
              searchStartDate.startOf("week").toFormat("dd.MM.yyyy") +
                " - " +
                searchStartDate.endOf("week").toFormat("dd.MM.yyyy")}
          </div>
          <Tooltip title={t("next")} placement="top">
            <div>
              <DoraButton
                variant={"primary"}
                style={{ padding: "7px 10px" }}
                onClick={onNextClick}
              >
                <ArrowForward style={{ fontSize: 16 }} />
              </DoraButton>
            </div>
          </Tooltip>
        </div>
        <div
          className="flex gap-2 justify-end items-center"
          style={{ zIndex: 1 }}
        >
          {moveSingleStopEnabled && (
            <div style={{ color: "orange", whiteSpace: "nowrap" }}>
              {t("moveStopInRoute")}
            </div>
          )}
          <Tooltip
            title={t(
              showCurrentMomentLine ? "hideMomentLine" : "showMomentLine"
            )}
            placement="top"
          >
            <div>
              <DoraButton
                variant={showCurrentMomentLine ? "primary-filled" : "primary"}
                onClick={() => setShowCurrentMomentLine(!showCurrentMomentLine)}
                style={{ padding: "3px 6px" }}
              >
                <Adjust
                  style={{ color: !showCurrentMomentLine ? "" : "white" }}
                />
              </DoraButton>
            </div>
          </Tooltip>
          <Tooltip
            title={
              cargosWhereFirstRouteStopTimeIsMissing.length
                ? t("cargosMissingTimesOnStops")
                : ""
            }
            placement="top"
          >
            <div>
              <DoraButton
                variant="primary"
                disabled={!cargosWhereFirstRouteStopTimeIsMissing.length}
                onClick={() => setMissingTimesAlertDialogOpen(true)}
                style={{ padding: "3px 6px" }}
              >
                <ReportProblem
                  style={{
                    color: !cargosWhereFirstRouteStopTimeIsMissing.length
                      ? "var(--gray-500)"
                      : "var(--warning-500)",
                  }}
                />
              </DoraButton>
            </div>
          </Tooltip>
          <Tooltip
            title={t(
              showCollisionsMultipleRows
                ? "collapseCollidingRows"
                : "expandCollidingRows"
            )}
            placement="top"
          >
            <div>
              <DoraButton
                variant={
                  showCollisionsMultipleRows ? "primary-filled" : "primary"
                }
                onClick={() =>
                  setShowCollisionsMultipleRows(!showCollisionsMultipleRows)
                }
                style={{ padding: "3px 6px" }}
              >
                <Expand
                  style={{ color: showCollisionsMultipleRows ? "white" : "" }}
                />
              </DoraButton>
            </div>
          </Tooltip>
          <Tooltip title={t("zoomIn")} placement="top">
            <div>
              <DoraButton
                variant="primary"
                style={{ padding: "3px 6px" }}
                disabled={zoomFactor >= 10}
                onClick={increaseZoom}
              >
                <Add />
              </DoraButton>
            </div>
          </Tooltip>
          <Tooltip title={t("zoomOut")} placement="top">
            <div>
              <DoraButton
                variant="primary"
                style={{ padding: "3px 6px" }}
                disabled={zoomFactor <= 1}
                onClick={decreaseZoom}
              >
                <Remove />
              </DoraButton>
            </div>
          </Tooltip>
          {/*<DoraButton*/}
          {/*  variant={*/}
          {/*    groupStopsWhenMovingInSameRoute ? "primary-filled" : "primary"*/}
          {/*  }*/}
          {/*  onClick={() =>*/}
          {/*    setGroupStopsWhenMovingInSameRoute(*/}
          {/*      !groupStopsWhenMovingInSameRoute*/}
          {/*    )*/}
          {/*  }*/}
          {/*>*/}
          {/*  {groupStopsWhenMovingInSameRoute ? "Ungroup stops" : "Group stops"}*/}
          {/*</DoraButton>*/}
        </div>
      </div>
    </>
  );
};

export default CalendarViewPageTopControls;
