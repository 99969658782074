import { DateTime } from "luxon";
import { CargoTemplateFormData } from "./types";
import { v4 as uuid } from "uuid";
import { NewShipmentDateOffset } from "../../data/org-settings/types";
import { CargoTemplateFromApi } from "./index";
import * as t from "io-ts";
import { Stop, stopT } from "../cargo-and-template-shared";
import { apiStopToFormStop } from "../cargo-dialog/helpers";

export const getStopDate = (daysAdjustment: NewShipmentDateOffset | null) =>
  DateTime.now()
    .plus({ day: parseInt(daysAdjustment || "1") })
    .toFormat("yyyy-MM-dd");

const defaultLoadmeters = 13.6;

export const emptyStop = (
  newShipmentDateOffset: NewShipmentDateOffset | null
): Stop => ({
  flipKey: uuid(),
  loadmeters: 0,
  modified: false,
  fix: false,
  place: null,
  weight: "",
  colli: "",
  length: "",
  height: "",
  width: "",
  date: getStopDate(newShipmentDateOffset),
  cubicMeters: "",
  temperature: "",
  time: "",
  ref: "",
  tag: "",
  contact: "",
  contactPhone: "",
  pieces: [],
  openingTime: "",
  openingDate: "",
});

export const defaultFormData = ({
  assignedTeamId,
  newShipmentDateAdjustment,
}: {
  assignedTeamId: string;
  newShipmentDateAdjustment: NewShipmentDateOffset | null;
}): CargoTemplateFormData => {
  return {
    clientContact: "",
    clientContactEmail: "",
    clientContactPhone: "",
    driverCanAddDropoffs: false,
    invoiceRef: "",
    cargoTagId: null,
    smartMatch: false,
    customerContactId: null,
    description: "",
    qualities: [],
    requirements: [],
    type: "MISCELLANEOUS",
    note: "",
    assignedTeamId,
    minTemperature: "",
    maxTemperature: "",
    containerNo: "",
    pickupList: [
      {
        ...emptyStop(newShipmentDateAdjustment),
        loadmeters: defaultLoadmeters,
        modified: false,
      },
    ],
    dropoffList: [
      {
        ...emptyStop(newShipmentDateAdjustment),
        loadmeters: defaultLoadmeters,
        modified: false,
      },
    ],
    extraCo2Elements: [],
  };
};

type ApiStop = t.TypeOf<typeof stopT>;

export const apiCargoTemplateToFormData = (
  data: CargoTemplateFromApi
): CargoTemplateFormData => ({
  ...data,
  clientId: data.clientId || undefined,
  customerContactId: data.customerContactId || null,
  invoiceRef: data.invoiceRef || null,
  description: data.description || "",
  qualities: data.qualities || [],
  requirements: data.requirements || [],
  clientContactEmail: data.clientContactEmail || "",
  clientContactPhone: data.clientContactPhone || "",
  clientContact: data.clientContact || "",
  driverCanAddDropoffs: data.driverCanAddDropoffs || false,
  minTemperature: data.minTemperature || "",
  maxTemperature: data.maxTemperature || "",
  containerNo: data.containerNo || "",
  pickupList: data.pickupList.map(apiStopToFormStop),
  dropoffList: data.dropoffList.map(apiStopToFormStop),
  note: data.note || "",
});

export const formDataStopToApiStop = (stop: Stop): ApiStop => {
  return {
    ...stop,
    loadmeters: stop.loadmeters.toString(),
  };
};
