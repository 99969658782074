import { CalendarViewRouteData } from "../../ducks/data/calendar-view/types";
import { useCalendarViewControls } from "./CalendarViewControlContext";
import timeToWidth from "./time-to-width";
import {
  CalendarViewRoutesStopsRowsRelativeHeightPlaceholder,
  CalendarViewRouteStopsRows,
} from "./CalendarViewRoutesStopsRows";
import { DateTime } from "luxon";
import clsx from "clsx";
import { useDrop } from "react-dnd";
import { CalendarViewDrawnStop } from "./interfaces";
import React, { useRef } from "react";
import { useAppDispatch, useSelector } from "../../redux-store";
import {
  moveStopInRoute,
  shiftCargoStopsTime,
} from "../../ducks/data/calendar-view";
import { moveCargo } from "../../ducks/dispatchActions";
import { CALENDAR_VIEW_DRAGGABLE_CARGO_STOP } from "./constants";
import { CARGO_LIST_DRAGGABLE_CARGO } from "../DispatchPage/CargoList/CargoCard";
import { selectStartDate } from "../../ducks/data/calendar-view/selectors";
import { RouteNameForRouteCard } from "../../Components/dispatching-planning/route-planning/route-planning";
import CalendarViewRouteDrivers from "./CalendarViewRouteDrivers";

// TODO: this really should be passed from one hook to another, but the right value doesn't get through. Still, it should be implemented properly
let moveSingleStopEnabledOutsideScope = false;
let colWithOutsideScope: number;
let hoverPositionOutsideScope: DateTime | null;

const CalendarViewRouteWithStops = ({
  route,
}: {
  route: CalendarViewRouteData;
}) => {
  const { routeStartTime, routeEndTime, routeStartDate, routeEndDate, id } =
    route;
  const {
    colWidth,
    viewTimeInterval,
    setMoveSingleStopEnabled,
    hoverPosition,
  } = useCalendarViewControls();
  const currentViewRightEdge =
    colWidth * (viewTimeInterval === "week" ? 7 : 1) * 24;

  const dispatch = useAppDispatch();

  const dropRef = useRef<HTMLDivElement>(null);

  const { moveSingleStopEnabled } = useCalendarViewControls();

  moveSingleStopEnabledOutsideScope = moveSingleStopEnabled;
  colWithOutsideScope = colWidth;
  hoverPositionOutsideScope = hoverPosition;

  const [{ foreignStopIsOver }, drop] = useDrop(() => ({
    accept: [CALENDAR_VIEW_DRAGGABLE_CARGO_STOP, CARGO_LIST_DRAGGABLE_CARGO],
    collect: (monitor) => {
      const isOver = monitor.isOver({ shallow: true });
      if (!isOver) {
        return { foreignStopIsOver: false };
      }
      const item = monitor.getItem();
      if (monitor.getItemType() === CALENDAR_VIEW_DRAGGABLE_CARGO_STOP) {
        const { drawnStop } = item as { drawnStop: CalendarViewDrawnStop };
        return {
          foreignStopIsOver:
            drawnStop.routeId !== route.id && !moveSingleStopEnabled,
        };
      } else {
        return { foreignStopIsOver: true };
      }
    },
    drop: (
      item:
        | {
            drawnStop: CalendarViewDrawnStop;
          }
        | any,
      monitor
    ) => {
      if (monitor.getItemType() === CARGO_LIST_DRAGGABLE_CARGO) {
        const { cargoId } = item as { cargoId: string };
        dispatch(
          moveCargo({
            sourceRouteId: null,
            cargoId,
            destRouteId: route.id,
          })
        );
      }
      if (monitor.getItemType() === CALENDAR_VIEW_DRAGGABLE_CARGO_STOP) {
        if (!hoverPositionOutsideScope) {
          console.error("Could not get hover position");
          return;
        }
        const { drawnStop } = item as { drawnStop: CalendarViewDrawnStop };
        if (
          moveSingleStopEnabledOutsideScope &&
          drawnStop.routeId !== route.id
        ) {
          setMoveSingleStopEnabled(false);
          return;
        }

        if (drawnStop.type === "DROPOFF" && moveSingleStopEnabledOutsideScope) {
          const restOfCargoPickups = route.cargos
            .find((cc) => cc.id === drawnStop.cargoId)
            ?.stops.filter(
              (ss) => ss.type === "PICKUP" && !ss.startTimeAssumed
            );
          for (const pickup of restOfCargoPickups!) {
            let d = DateTime.fromFormat(pickup.date, "yyyy-MM-dd");
            let tt = pickup.startTime!;
            if (pickup.stopTime && !pickup.stopTimeAssumed) {
              tt = pickup.stopTime;
            }
            const [hh, mm] = tt.split(":");
            d = d.set({
              hour: parseInt(hh),
              minute: parseInt(mm),
            });
            if (d > hoverPositionOutsideScope) {
              alert("Can't move dropoff before pickups of same cargo");
              setMoveSingleStopEnabled(false);
              return;
            }
          }
        }

        if (route.id !== drawnStop.routeId) {
          setMoveSingleStopEnabled(false);
          if (!moveSingleStopEnabledOutsideScope) {
            dispatch(
              moveCargo({
                sourceRouteId: drawnStop.routeId,
                destRouteId: route.id,
                cargoId: drawnStop.cargoId,
              })
            );
          }
          return;
        }

        if (!moveSingleStopEnabledOutsideScope) {
          const diffInMinutes = Math.floor(
            hoverPositionOutsideScope.diff(drawnStop.dateTime, "minutes")
              .minutes
          );
          dispatch(
            shiftCargoStopsTime({
              cargoId: drawnStop.cargoId,
              minutesAmount: diffInMinutes,
            })
          );
        } else {
          dispatch(
            moveStopInRoute({
              stopId: drawnStop.stopId,
              cargoId: drawnStop.cargoId,
              date: hoverPositionOutsideScope.toFormat("yyyy-MM-dd"),
              time: hoverPositionOutsideScope.toFormat("HH:mm"),
            })
          );
        }
        setMoveSingleStopEnabled(false);
      }
    },
  }));

  const startDate = useSelector(selectStartDate);

  const daysOffsetCalcStart =
    viewTimeInterval === "week"
      ? startDate.startOf("week")
      : startDate.startOf("day");

  const daysOffsetRouteStart = Math.ceil(
    DateTime.fromISO(routeStartDate).diff(daysOffsetCalcStart, "days").days
  );
  const daysOffsetRouteEnd = Math.ceil(
    DateTime.fromISO(routeEndDate).diff(daysOffsetCalcStart, "days").days
  );

  let leftEdge = true;
  let rightEdge = true;

  let posXStart = timeToWidth(routeStartTime, colWidth, daysOffsetRouteStart);
  if (posXStart < 0) {
    posXStart = 0;
    leftEdge = false;
  }

  let posXEnd = timeToWidth(routeEndTime, colWidth, daysOffsetRouteEnd);
  if (posXEnd > currentViewRightEdge) {
    posXEnd = currentViewRightEdge;
    rightEdge = false;
  }

  const width = posXEnd - posXStart;

  const classes = clsx("calendar-view-route-with-stops-inner", {
    "no-edge-left": !leftEdge,
    "no-edge-right": !rightEdge,
    "has-item-hover-over": foreignStopIsOver,
  });

  drop(dropRef);

  return (
    <div className="calendar-view-route-with-stops">
      <div
        className="calendar-view-route-with-stops-inner-wrapper"
        ref={dropRef}
        style={{ width, left: posXStart }}
      >
        <div className="flex items-center justify-between">
          <div className="calendar-view-route-name">
            <RouteNameForRouteCard routeId={route.id} iconToTheLeft />
          </div>
          <CalendarViewRouteDrivers route={route} />
        </div>
        <div className={classes}>
          <CalendarViewRouteStopsRows
            cargos={route.cargos}
            routeId={id}
            leftOffset={posXStart}
            trailerId={route.trailerId!}
          />
        </div>
      </div>
      <CalendarViewRoutesStopsRowsRelativeHeightPlaceholder
        cargos={route.cargos}
        routeId={id}
      />
    </div>
  );
};

export default CalendarViewRouteWithStops;
