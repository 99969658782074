import { useAppDispatch, useSelector } from "../../redux-store";
import React, { useEffect, useMemo } from "react";
import {
  changeSelectedIntervalAndLoadData,
  componentMount,
  componentUnmount,
} from "../../ducks/data/calendar-view";
import { selectDataForCalendarView } from "../../ducks/data/calendar-view/selectors";
import "./CalendarViewPage.scss";
import {
  selectTrailer,
  selectTrailers,
} from "../../ducks/data/trailers/selectors";
import { loadTrailers } from "../../ducks/data/trailers";
import { loadDrivers } from "../../ducks/data/drivers";
import { useCalendarViewControls } from "./CalendarViewControlContext";
import CalendarViewTimelineTopBar from "./CalendarViewTimelineTopBar";
import CalendarViewCurrentMomentVerticalLine from "./CalendarViewCurrentMomentVerticalLine";
import CalendarViewPageTopControls from "./CalendarViewPageTopControls";
import CalendarViewTrailerRowsSection from "./CalendarViewTrailerRowsSection";
import useResizeObserver from "use-resize-observer";

const CalendarViewPage = () => {
  const dispatch = useAppDispatch();

  // const calendarViewStatus = useSelector(selectRequestStatusForCalendarView);
  const calendarViewData = useSelector(selectDataForCalendarView);

  const { viewTimeInterval, rowSectionHeights, hoverPosition } =
    useCalendarViewControls();

  useEffect(() => {
    dispatch(componentMount());
    return () => {
      dispatch(componentUnmount());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(changeSelectedIntervalAndLoadData(viewTimeInterval));
    dispatch(loadTrailers());
    dispatch(loadDrivers());
  }, [dispatch]);

  const trailers = useSelector(selectTrailers);

  const trailerSectionsSorted = useMemo(() => {
    return [...calendarViewData].sort((trailer1, trailer2) => {
      const t1 = trailers.find((t) => t.id === trailer1.trailerId)!;
      const t2 = trailers.find((t) => t.id === trailer2.trailerId)!;
      return (t1.alias || t1.number).localeCompare(t2.alias || t2.number);
    });
  }, [calendarViewData, trailers]);

  const { ref, height } = useResizeObserver<HTMLDivElement>();

  return (
    <div className="calendar-view-page" ref={ref}>
      <CalendarViewPageTopControls />
      <div
        className="flex"
        id="calendar-view-page__scroll-container"
        style={{
          overflowX: hoverPosition ? "hidden" : "auto",
          overflowY: "auto",
        }}
      >
        <div className="calendar-view-left-section">
          <div
            id="calendar-view-left-section__top-bumper"
            style={{ height: viewTimeInterval === "day" ? 30 : 57 }}
          ></div>
          {trailerSectionsSorted.map((trailerSection, index) => {
            const trailerSectionHeight =
              rowSectionHeights[trailerSection.trailerId];
            return (
              <div
                key={index}
                className="trailer-driver-section"
                style={{ height: trailerSectionHeight }}
              >
                <TrailerAliasAndNumber trailerId={trailerSection.trailerId} />
              </div>
            );
          })}
        </div>
        <div className="relative">
          <CalendarViewTimelineTopBar />
          {trailerSectionsSorted.map((trailerSection) => (
            <CalendarViewTrailerRowsSection
              key={trailerSection.trailerId}
              trailerRowsSection={trailerSection}
            />
          ))}
          <CalendarViewCurrentMomentVerticalLine
            height={height ? height + 270 : undefined}
          />
        </div>
      </div>
    </div>
  );
};

const TrailerAliasAndNumber = ({ trailerId }: { trailerId: string }) => {
  const trailer = useSelector(selectTrailer(trailerId));
  return (
    <div>
      <div style={{ fontSize: 16 }}>{trailer?.alias || trailer?.number}</div>
      {trailer?.alias && (
        <div style={{ fontSize: 14, color: "darkgray" }}>{trailer.number}</div>
      )}
    </div>
  );
};

export default CalendarViewPage;
